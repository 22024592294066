const header = document.querySelector(".main_header");
const navToggle = document.querySelector(".main_header-nav-toggle");
const videoLinks = document.querySelectorAll(".front_page-videos-grid-c");
const videos = document.querySelectorAll(".front_page-videos-video");

navToggle?.addEventListener("click", () => {
  header?.classList.toggle("open");
});

videoLinks.forEach((link, i) =>
  link.addEventListener("click", () => {
    videoLinks.forEach((l) => l.classList.toggle("inactive", l == link));
    videos.forEach((v, j) => v.classList.toggle("active", i == j));
    document.querySelector(`#video-${i + 1}`)?.scrollIntoView();
  })
);
